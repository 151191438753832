<template>
  <b-row align-h="center">
    <b-col>
      <b-overlay>
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <div class="h4">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" class="mt-2 go-back"
                   xmlns="http://www.w3.org/2000/svg"
                   @click="$router.push('/governance/voting')"
                   clickable>
                <rect width="1.83333" height="12.8333" rx="0.916667" transform="matrix(0 -1 -1 0 17.4165 11.9165)"
                      fill="#1BC5BD"/>
                <path
                    d="M11.6483 15.8517C12.0063 16.2096 12.0063 16.79 11.6483 17.148C11.2904 17.506 10.71 17.506 10.352 17.148L4.85198 11.648C4.50495 11.301 4.49281 10.7422 4.82444 10.3804L9.86611 4.88042C10.2082 4.50723 10.7881 4.48202 11.1612 4.82411C11.5344 5.1662 11.5596 5.74606 11.2176 6.11925L6.76896 10.9723L11.6483 15.8517Z"
                    fill="#1BC5BD"/>
              </svg>
              {{ getProposal.proposal_title }}
            </div>
          </template>
          <template v-if="!isStepExists(stepperUuid)">
            <div class="form-pool-choice px-5 pt-3 pb-4">
              <b-form-row align-v="center" align-h="center" no-gutters>
                <b-col md="auto" class="w-auto caption my-3">
                  Contract address
                </b-col>
                <b-col md="auto" class="w-auto my-3 font-weight-bolder proposal-padding">
                  {{ getProposal.proposal_values ? getProposal.proposal_values[0] : '' }}
                </b-col>
                <b-col md="auto" class="w-auto caption my-3" v-if="getProposal.proposal_values"
                       v-clipboard="getProposal.proposal_values ? getProposal.proposal_values[0]: ''" clickable>
                  <b-icon-files></b-icon-files>
                  Copy
                </b-col>
              </b-form-row>
            </div>
            <b-row class="mx-5 my-3 mb-5">
              <b-col md="6">
                <b-form-row>
                  <b-col class="pt-3">
                    <TextBlock label="Voting start"
                               :amount="getDate(getProposal.start_date)"
                               :noParse="true"
                    ></TextBlock>
                  </b-col>
                  <b-col class="pt-3">
                    <TextBlock label="Voting end"
                               :amount="getDate(getProposal.end_date)"
                               :noParse="true"
                    ></TextBlock>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="pt-3">
                    <TextBlock label="Quorum"
                               :amount="getQuorum()"
                               :noParse="true"
                               :amountTooltip="quorumAmountTooltip"
                    ></TextBlock>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col class="pt-3">
                    <TextBlock label="Your LQF tokens"
                               :amount="mathRound(convertFromWei(this.lqfBalance), 6).toString()"
                               :amountTooltip="bNValueFromWei(this.lqfBalance)"
                               append="LQF"
                    ></TextBlock>
                  </b-col>
                </b-form-row>
              </b-col>
              <b-col md="6" class="pt-3">
                <div class="caption">
                  Votes
                </div>
                <div class="data">
                  <div class="text-primary mb-1">
                    {{ getPercentageRatio(getProposal.votes_yes) }}% yes
                    ({{ mathRound(getProposal.votes_yes, 0) }} LQF)
                  </div>
                  <div class="mb-1">
                    {{ getPercentageRatio(getProposal.votes_no) }}% no
                    ({{ mathRound(getProposal.votes_no, 0) }} LQF)
                  </div>
                  <div class="mb-1">
                    {{ getPercentageRatio(getProposal.votes_abstain) }}% abstain
                    ({{ mathRound(getProposal.votes_abstain, 0) }} LQF)
                  </div>
                  <div class="mb-1">
                    {{ getPercentageRatio(getProposal.votes_veto) }}% veto
                    ({{ mathRound(getProposal.votes_veto, 0) }} LQF)
                  </div>

                </div>

              </b-col>
            </b-row>
          </template>
          <template v-else-if="isStepExists(stepperUuid)">
            <Step :con-data="confirmationPopupData" @close="onCloseStepper"></Step>
            <StepInformer :steps="getStepsById(stepperUuid)" :stepperUuid="stepperUuid"></StepInformer>
          </template>
          <template v-slot:footer v-if="showVoteBtns && stepperUuid && !isStepExists(stepperUuid)">
            <b-row class="mb-3">
              <b-col>
                <b-button block class="button-stroked"
                          value="no"
                          v-on:click="openConfirmationPopup($event)">
                  No
                </b-button>
              </b-col>
              <b-col>
                <b-button block class="button-stroked"
                          value="yes"
                          v-on:click="openConfirmationPopup($event)">
                  Yes
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button block class="button-stroked"
                          value="abstain"
                          v-on:click="openConfirmationPopup($event)">
                  Abstain
                </b-button>
              </b-col>
              <b-col>
                <b-button block class="button-stroked"
                          value="veto"
                          v-on:click="openConfirmationPopup($event)">
                  Veto
                </b-button>
              </b-col>
            </b-row>
          </template>
          <template v-slot:footer v-else-if="showFinalizeVoting && stepperUuid && !isStepExists(stepperUuid)">
            <b-button block class="button-stroked"
                      value="finalize"
                      v-on:click="openConfirmationPopup($event)">
              Finalize voting
            </b-button>
          </template>
          <ConfirmationVotingModal :ref="'confirmation-voting-modal'"
                                   :id="'confirmation-voting-modal'"
                                   :con-data="confirmationPopupData"
                                   @ok="onConfirmOk"
          ></ConfirmationVotingModal>

        </b-card>
      </b-overlay>
    </b-col>
  </b-row>

</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import {
  HTTP_GET_CHECK_IS_VOTED,
  HTTP_GET_PROPOSAL_BY_ID,
  MINTER_BALANCE_OF,
  MINTER_TOTAL_SUPPLY,
  VOTE,
  VOTE_FINALIZE
} from "@/store/modules/voting.module";
import {mathRound} from "@/helpers/utils";
import BigNumber from "bignumber.js";
import {uuid} from "vue-uuid";
import {DELETE_STEP_BY_ID, INIT_STEP} from "@/store/modules/stepper.module";

export default {
  name: "Proposal",
  components: {
    TextBlock: () => import("@/components/form/TextBlock"),
    ConfirmationVotingModal: () => import("@/components/confirmation/voting/ConfirmationVotingModal"),
    Step: () => import("@/components/stepper/Step"),
    StepInformer: () => import("@/components/stepper/StepInformer"),
  },
  props: {
    id: String
  },
  data: function () {
    return {
      getProposal: {},
      lqfBalance: '-',
      quorumMinterSupply: '-',
      voteResult: '',
      isVoted: true,
      quorumAmountTooltip: null,
      lqfAmountTooltip: null,
      confirmationPopupData: {},
      stepperUuid: uuid.v1(),
    }

  },
  async mounted() {
    await this.getData()
  },
  methods: {
    ...mapActions([
      HTTP_GET_PROPOSAL_BY_ID,
      HTTP_GET_CHECK_IS_VOTED,
      MINTER_BALANCE_OF,
      MINTER_TOTAL_SUPPLY,
      VOTE,
      VOTE_FINALIZE,
      INIT_STEP,
      DELETE_STEP_BY_ID
    ]),
    async getData() {
      const params = {
        proposal_id: this.id
      }
      await this.httpGetProposalById(params).then(res => {
        this.getProposal = res
      })
      await this.httpGetCheckIsVoted(params).then(res => {
        this.isVoted = res.voted
      })
      await this.minterBalanceOf().then(res => {
        this.lqfBalance = res
      })
      await this.minterTotalSupply().then(res => {
        this.quorumMinterSupply = this.convertFromWei(res)
      })
    },
    getDate: function (date) {
      return moment.unix(date).format('DD/MM/YYYY HH:mm');
    },
    getPercentageRatio: function (value) {
      const sum = new BigNumber(this.getProposal.votes_yes).plus(this.getProposal.votes_no).plus(this.getProposal.votes_abstain)
      if (+sum.valueOf() === 0 || isNaN(sum.valueOf())) {
        return 0
      }
      return mathRound((value / sum) * 100)
    },
    openConfirmationPopup: function (e) {
      this.voteResult = e.target.value
      this.confirmationPopupData = {}
      this.confirmationPopupData = this.getConfirmationData()
      this.$refs['confirmation-voting-modal'].$refs['confirmation-voting-modal'].show()
    },
    assignConfirmationData(data) {
      this.confirmationPopupData = Object.assign(this.confirmationPopupData, data)
    },
    getConfirmationData() {
      //TODO make confirmationData in general way
      let confirmationData = {
        title: `You are voting on ${this.getProposal.proposal_title}`,
        modalType: 'confirm',
        waiting: false,
        voteResult: this.voteResult,
      }
      if (this.voteResult === 'finalize') {
        confirmationData.proposalTitle = `${this.getProposal.proposal_title}: finalize voting`
        confirmationData.modalTitle = 'Finalize voting'
      }
      return confirmationData
    },
    async onCloseStepper() {
      this.deleteStepById(this.stepperUuid)
      await this.getData()
      this.stepperUuid = uuid.v1()
    },
    onConfirmOk(op) {
      if (op) {
        this.initStep(this.stepperUuid)
        this.$refs['confirmation-voting-modal'].$refs['confirmation-voting-modal'].hide()

        if (this.voteResult === 'finalize') {
          this.performFinalize()
          return
        }
        this.performVoting()
      }
    },
    async performFinalize() {
      this.assignConfirmationData({waiting: true, modalType: 'confirm'})
      const params = {
        proposal_id: this.id,
        stepUuid: this.stepperUuid
      }
      await this.voteFinalize(params).then(async (tx) => {
        this.assignConfirmationData({
          etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
          successMessage: 'Success!',
          waiting: false,
          modalType: 'success'
        })
        await this.getData()
      }).catch((e) => {
        this.assignConfirmationData(
            {modalType: 'error', errorMessage: e.message, waiting: false})
      })
    },
    async performVoting() {
      this.assignConfirmationData({waiting: true, modalType: 'confirm'})
      const params = {
        vote: this.getIdInMappedVotes,
        proposal_id: this.id,
        stepUuid: this.stepperUuid
      }
      await this.vote(params).then(async (tx) => {
        this.assignConfirmationData({
          etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
          successMessage: 'Success!',
          waiting: false,
          modalType: 'success'
        })
        await this.getData()
      }).catch((e) => {
        this.assignConfirmationData(
            {modalType: 'error', errorMessage: e.message, waiting: false})
      })
    },
    mathRound(number, decimals = 8) {
      return mathRound(number, decimals)
    },
    bNValue(value) {
      return new BigNumber(value).valueOf()
    },
    bNValueFromWei(value) {
      return new BigNumber(value).dividedBy(Math.pow(10, 18)).valueOf()
    },
    getQuorum() {
      let sum = new BigNumber(this.getProposal.votes_yes).plus(this.getProposal.votes_no).plus(this.getProposal.votes_abstain)
      let sumResult = mathRound(sum.valueOf(), 0)
      let total = mathRound(this.quorumMinterSupply, 0) || '0'

      this.quorumAmountTooltip = `${sum.valueOf()} of ${new BigNumber(this.quorumMinterSupply).valueOf()}`
      return `${sumResult} of ${total}`
    },
    resetForm() {
      this.$data = {
        getProposal: {},
        lqfBalance: '-',
        quorumMinterSupply: '-',
        voteResult: '',
        quorumAmountTooltip: null,
        lqfAmountTooltip: null,
        isVoted: null,
        confirmationPopupData: this.confirmationPopupData
      }
    }
  },
  computed: {
    ...mapGetters([
      'convertFromWei',
      'getEtherscanTxLink',
      'isStepExists',
      'getStepsById'
    ]),
    showVoteBtns() {
      return !this.getProposal.voting_result && !this.isVoted && (this.getProposal.end_date > moment(new Date()).unix())
    },
    showFinalizeVoting() {
      return (this.getProposal.end_date < moment(new Date()).unix()) && !this.getProposal.voting_result
    },
    getIdInMappedVotes() {
      let result = null
      switch (this.voteResult) {
        case "yes":
          result = 1;
          break;
        case "no":
          result = 2;
          break;
        case "abstain":
          result = 3;
          break;
        case "veto":
          result = 4;
          break;
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">
  .proposal-padding {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
</style>
